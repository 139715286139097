import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "accountList",

  setup() {
    // 工具列表
    const tools = reactive([{
      id: "7303837551@airtel",
      phone: "7303837551",
      active: false,
      showDropdown: false // 控制下拉框显示

    }, {
      id: "7417005952@ikwik",
      phone: "7417005952",
      active: true,
      showDropdown: false
    }, {
      id: "7393046677@axl",
      phone: "7393046677",
      active: true,
      showDropdown: false
    }]);
    const router = useRouter(); // 切换下拉框的显示与隐藏

    const toggleDropdown = index => {
      tools.forEach((tool, i) => {
        if (i === index) {
          tool.showDropdown = !tool.showDropdown;
        } else {
          tool.showDropdown = false; // 关闭其他下拉框
        }
      });
    };

    const toDetail = path => {
      router.push(path);
    }; // 处理下拉菜单的选项


    const handleAction = (tool, action) => {
      console.log(`Action: ${action} for tool: ${tool.id}`);
      tool.showDropdown = false; // 选中后关闭下拉框
    };

    return {
      tools,
      toggleDropdown,
      handleAction,
      toDetail
    };
  }

});